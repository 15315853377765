@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,700&display=swap');

$semi-bold: 600;

:root {
    --is-large: 0;
}

.largeFonts {
    --is-large: 1;
    h3 {
        font-size: 25px;
    }
    .mat-tab-label-content {
        font-size: 32px;
    }
}
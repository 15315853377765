/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@import "app/app.component";
@import '@angular/material';
@include mat.core();
$theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$red-palette,
  ),
));

html {
  @include mat.all-component-themes($theme);
}

mat-form-field {
  width: 100%
}

* {
  box-sizing: border-box;
}

.ngx-pagination .current {
  background: #b21515 !important;
}

html {
  min-height: 100%;
  display: flex;
}

body,
.mat-tab-group,
.mat-card,
.mat-tab-label {
  font-family: 'Open Sans';
}

lib-button {
  width: initial !important;
}

textarea,
select,
input {
  font-family: 'Open Sans';

  * {
    font-family: 'Open Sans';
  }
}

body {
  flex: 1;
  font-size: 14px;
  width: 100vw;
}

html,
body {
  padding: 0;
  margin: 0;
}

.sectionTitle {
  width: 250px;
}

h1 {
  font-size: 16px;
  font-weight: normal;
  color: #6E717A;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
  border-bottom: 1px solid gray;
}

h4, h5, h6 {
  text-align: left;
  font-weight: normal;
  margin: 10px 0;
}

h4 {
  color: var(--header-color);
  font-size: 16px;
}

h5 {
  text-transform: uppercase;
  font-size: 20px;
}

h6 {
  font-size: 15px;
}

.spacer {
  flex: auto;
}


.pointer {
  cursor: pointer;
}

.headericon {
  padding-top: 10px;
  display: flex;
  align-items: center
}

.dropdown-arrow {
  cursor: pointer;

  &:after {
    content: '';
    display: block;
    width: 17px;
    height: 10px;
    margin-left: 16px;
    transition: transform .5s;

    @include mqube-mobile() {
      width: 10px;
      height: 6px;
      margin-left: 8px;
    }
  }

  &__delay {
    &:after {
      transition-delay: .5s;
    }
  }

  &._opened {
    &:after {
      transform: rotate(180deg);
      transition: transform .5s;
    }
  }
}

.container {
  width: 100%;
  display: block;
}

.half {
  width: 50%;
  display: inline-block;
  padding: 10px;
  float: left;
}

.quarter {
  width: 25%;
  display: inline-block;
}

.threequarter {
  width: 75%;
  display: inline-block;
}

.passToolTip,
.failToolTip,
.warnTooltip {
  font-size: 14px;
}

.passToolTip {
  background-color: green;
}

.failToolTip {
  background-color: #b71c1c;
}

.warnToolTip {
  background-color: orange;
}

.notRunToolTip {
  background-color: gray;
}

.passIcon {
  color: var(--pass-color-1) !important;
}

.warnIcon {
  color: var(--warn-color-1) !important;
}

.failIcon {
  color: var(--fail-color-1) !important;
}

.overrideIcon,
.notRunIcon {
  color: gray;
}

mat-tab-header {
  margin-bottom: 20px;
}

.mat-tab-label-content {
  font-size: 18px;
  text-transform: uppercase;
}

.mat-ink-bar {
  width: 100px !important;
  margin-left: 30px !important;
}

mat-expansion-panel-header {
  span {
    align-items: center;
  }
}

markdown {
  color: var(--text-color-1);
}

markdown > table,
.datatable {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;

  thead {
    font-weight: 600;

    tr {
      td {
        border-bottom: 1px solid #99999999;
      }
    }
  }

  &.alternate {
    tbody {
      tr:nth-child(odd) {
        background-color: var(--panel-color-2);
      }
    }
  }

  thead,
  tr {
    th,
    td {
      padding: 7px 5px;
      font-size: 12px;
      border-bottom: 1px solid #99999955;
      text-align: left;
      // transition: all 0.5s;

      &.center {
        text-align: center;
      }

      &.right {
        text-align: right;
      }

      &.icon {
        width: 110px;

        i {
          font-size: 25px;
          cursor: pointer;
        }
      }

      &.shevron {
        background: url(assets/img/dropdown-shevron-small-black.svg) no-repeat center;
        width: 30px;
        background-size: auto;
      }
    }

    &.expanded {
      td {
        border-bottom: 0px none transparent;
        background-color: var(--main-bg-color);

        &.shevron {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.dark {
  .datatable {
    tr {
      td {
        &.shevron {
          background-image: url(assets/img/dropdown-shevron-small.svg);
        }
      }
    }
  }
}

.actionbar {
  display: flex;
  flex-direction: row;
  align-items: center;

  .spacer {
    flex: 1;
  }

  .button__content {
    font-weight: 300;

    i {
      margin-right: 5px;
    }
  }
}

.dropfield {
  border-radius: 25px;
  height: 40px;
  background-color: var(--main-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0px 2px 3px 0px rgba(100, 100, 100, 0.5);

  &.dark {
    box-shadow: inset 0px 2px 3px 0px rgba(0, 0, 0, 0.5);
  }

  &.search {
    width: 448px;
  }

  .icon {
    margin: 0 10px;
    color: gray;
    font-size: 18px;

    &.disabled {
      opacity: 0.3;
    }
  }

  select,
  input {
    flex: 1;
    border: 0 transparent none;
    background-color: transparent;
    outline: transparent;
    font-size: 18px;
    width: 100%;
    color: var(--text-color-1);
  }
}

.mqube-popup-overlay {
  z-index: 9999 !important;
}

.cdk-overlay-container {
  z-index: 10000 !important;
}

.mqube-popup__header {
  font-size: 25px !important;
  font-weight: 300 !important;
  text-align: left !important;
  width: 100% !important;
  margin: -25px 0 20px 0 !important;
  line-height: unset !important;
  font-family: 'Open Sans';
  line-height: unset !important;
}

.mqube-popup__content {
  flex-direction: column;
  text-align: left !important;
}

.mqube-checkbox-group {
  padding-bottom: 0 !important;
  text-align: left;
}

*:disabled {
  opacity: 0.5;
}

@import "dark";
@import "light";

.mqube-button {
  &._small {
    font-size: 13px !important;
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--header-color);
  box-shadow: 0 0 1px rgba(255,255,255,.5);
}


body {
  --color-orange: #E08330;
  --pass-color-1: #64c362;
  --pass-color-2: #64c36255;
  --pass-color-3: #64c36233;
  --warn-color-1: #FFA500;
  --warn-color-2: #FFA50055;
  --warn-color-3: #FFA50033;
  --fail-color-1: #dc5a59;
  --fail-color-2: #dc5a5955;
  --fail-color-3: #dc5a5933;

  &.colourBlind {
    --pass-color-1: #6074f6;
    --pass-color-2: #6074f655;
    --pass-color-3: #6074f633;
    --warn-color-1: #958702;
    --warn-color-2: #95870299;
    --warn-color-3: #95870269;
    --fail-color-1: #fce600;
    --fail-color-2: #fce60099;
    --fail-color-3: #fce60069;
  }
}


.dropdown-arrow {
  &:after {
    mask-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='17px' height='10px' viewBox='0 0 17 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: sketchtool 61.1 (101010) - https://sketch.com --%3E%3Ctitle%3E4CEC24FC-8ADE-460A-90C4-26F4B7281AE0%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cg id='Asset-Artboard-Page' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round' stroke-opacity='0.89767264'%3E%3Cg id='nav/dashboard/username-header/dashboard/username' transform='translate(-1703.742631, -46.000000)' stroke='%23000000' stroke-width='2'%3E%3Cpolyline id='dropdown-shevron-small' transform='translate(1712.000000, 51.000000) rotate(-270.000000) translate(-1712.000000, -51.000000) ' points='1708 44 1716 51 1708 58'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-color: var(--text-color-1);
    @include mqube-mobile() {
      mask-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='10px' height='6px' viewBox='0 0 10 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EA7905566-524B-43F5-9C12-49E6C13371E8%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' opacity='0.87' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='broker-dashboard-light' transform='translate(-294.000000, -393.000000)' stroke='%23121212' stroke-width='1.5'%3E%3Cpolyline id='dropdown-large-field-D8D8D8-Copy-3' transform='translate(299.000000, 396.000000) rotate(-270.000000) translate(-299.000000, -396.000000) ' points='297 392 301 396 297 400'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }
}

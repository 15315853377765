.customTheme {
  &.lightTheme {

    --theme-hue: var(--custom-theme-hue, 224);
    --saturation: 0%;
    --app-header-color: hsl(var(--theme-hue), 100%, 50%);
    --menu-bg-color: hsl(var(--theme-hue), var(--saturation), 100%);
    --main-bg-color: hsl(var(--theme-hue), var(--saturation), 96%);
    --header-color: hsl(calc(var(--theme-hue) - 13), 65%, 40%);
    --panel-color: hsl(var(--theme-hue), var(--saturation), 92%);
    --panel-color-2: hsl(var(--theme-hue), var(--saturation), 90%);
    --panel-color-3: hsl(var(--theme-hue), var(--saturation), 88%);
    --panel-color-4: hsl(var(--theme-hue), var(--saturation), 80%);
    --text-color-1: #000000;
    --text-color-inverted: #f5f5f5;
    --scroll-colour: rgba(255, 255, 255, 0.2);
    --tab-color: black;
    --selected-tab-color: hsl(var(--theme-hue), 90%, 30%);
    color: var(--text-color-1);


    // --menu-bg-color: white;
    // --main-bg-color: #F5F5F5;
    // --header-color: #2466ad;
    // --panel-color: #EDEDED;
    // --panel-color-2: #E7E7E7;
    // --panel-color-3: #E1E1E1;
    // --panel-color-4: #DDDDDD;
    // --text-color-1: black;
    // --scroll-colour: rgba(0, 0, 0, 0.2);
    // --tab-color: #403751;
    // --selected-tab-color: #0041FE;
    // color: var(--text-color-1);

  }
}


@import "media";
@import "fonts";

.mqube-header {
  display: flex;
  justify-content: space-between;
  padding: 0;

  &__logout {
    display: flex;
    font-size: 16px;
    cursor: pointer;
    align-items: center;
  }

  .logout {
    margin-left: 4px;
  }
}

.mqube-app {
  margin: 0;
  height: 100vh;
  min-height: 100vh;
  width: calc(100% - 7.5vh);
  display: flex;
  flex-direction: column;
  overflow: auto;

  &.no-side-bar {
    margin-left: 0;
    width: 100%;
  }

  &__wrapper {
    padding: 60px 16px;
    flex: 1 0 auto;
    display: flex;
    justify-content: center;

    @include mqube-mobile() {
      padding: 60px 16px 0;
    }
  }

  &__container-wrapper {
    background-color: var(--main-bg-color);
    margin: 0 auto;
    min-height: 100vh;

    &.upsideDown {
      transition: all 1s;
      transform: rotate(180deg);
    }
  }

  &__container {
    display: flex;
    min-height: 100vh;
    overflow-x: hidden;
  }
}

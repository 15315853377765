.customTheme {
  &.darkTheme {
    --saturationFactor: var(--custom-theme-saturation, 1);
    --theme-hue: var(--custom-theme-hue, 224);
    --app-header-color: hsl(var(--theme-hue), calc(50% * var(--saturationFactor)), 50%);
    --menu-bg-color: hsl(var(--theme-hue), calc(100% * var(--saturationFactor)), 6%);
    --main-bg-color: hsl(var(--theme-hue), calc(100% * var(--saturationFactor)), 10%);
    --header-color: hsl(calc(var(--theme-hue) - 32), 100%, 80%);
    --panel-color: hsl(var(--theme-hue), calc(81% * var(--saturationFactor)), 14%);
    --panel-color-2: hsl(var(--theme-hue), calc(69% * var(--saturationFactor)), 18%);
    --panel-color-3: hsl(var(--theme-hue), calc(59% * var(--saturationFactor)), 21%);
    --panel-color-4: hsl(var(--theme-hue), calc(52% * var(--saturationFactor)), 25%);
    --text-color-1: rgba(255, 255, 255, 0.9);
    --text-color-inverted: #0b0022;
    --scroll-colour: rgba(255, 255, 255, 0.2);
    --tab-color: #F5F5F5;
    --selected-tab-color: hsl(var(--theme-hue), 65%, 70%);
    color: var(--text-color-1);

    h1 {
        color: rgba(255, 255, 255, 0.9);
    }
    h4 {
        border-bottom: 1px solid #ffffff77;
    }
    .mat-tab-label-content {
        color: #98CCC6;
    }
  }
}

